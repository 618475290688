import type { StreamPlayerApi } from '@cloudflare/stream-react'
import useHover from '@react-hook/hover'
import clsx from 'clsx'
import React, { CSSProperties, ReactNode, useCallback, useEffect, useRef, useState } from 'react'
import { useVisible } from 'react-hooks-visible'
import { FeedVideo } from '../../libs/cloudflare/FeedVideo'
import { createFetcher } from '../../libs/next/api/jsonApiHandler'
import { filterNonEmpty } from '../../libs/utils/filterNonEmpty'
import type { API } from '../../pages/api/content/[handler]'
import type { ColorResult } from '../data/fragments/ColorFragment'
import type { FeedVideoResult } from '../data/fragments/FeedVideoFragment'
import type { PartnerWithLatestVideoResult } from '../data/fragments/PartnerFragment'
import type { FomoFeedPagedData } from '../data/helpers/getFomoFeed'
import { getFomoFeedVideoUrl } from '../data/helpers/getFomoFeedVideoUrl'
import { getBaseUrl } from '../utils/getBaseUrl'
import { nthInsert } from '../utils/nthInsert'
import { scrollBy } from '../utils/scrollBy'
import { useIntersectionObserver } from '../utils/useIntersectionObserver'
import { usePagination } from '../utils/usePagination'
import { Arrow } from './Arrow'
import { Background } from './Background'
import { Container } from './Container'
import { FeedAuthor } from './FeedAuthor'
import style from './FomoFeed.module.sass'
import { Image } from './Image'
import { IntersectionNavigator } from './IntersectionNavigator'
import { Link } from './Link'
import { PartnerGrid } from './PartnerGrid'
import { PlayIcon } from './PlayIcon'

const api = createFetcher<API>('/api/content/[handler]')

export type FomoFeedProps = {
	background?: ColorResult
	title?: ReactNode
	feed: FomoFeedPagedData
	partners?: Array<PartnerWithLatestVideoResult>
}

const CAROUSEL_SCROLL_BY_DISTANCE = 400

export function FomoFeed(props: FomoFeedProps) {
	const { feed } = props

	const [feeds, handleNext] = usePagination([feed], (paged) =>
		api('fomoFeed', { _req: { query: { paged: String(paged) } } }).then((data) => data.feed)
	)

	const currentFeed = feeds[feeds.length - 1]

	const { promoItems, regularItems } = React.useMemo(() => {
		const regularItems: FomoFeedPagedData['regularItems'] = []
		const promoItems: FomoFeedPagedData['promoItems'] = []

		for (const feed of feeds) {
			regularItems.push(...feed.regularItems)
			promoItems.push(...feed.promoItems)
		}

		return { regularItems, promoItems }
	}, [feeds])

	const items = React.useMemo(() => {
		return nthInsert(regularItems, promoItems, 3)
			.filter((value, index, self) => {
				return self.findIndex((el) => el?.id === value?.id) === index
			})
			.filter(filterNonEmpty)
	}, [promoItems, regularItems])

	const [startCatchRef, setStartCatchRef] = useState<null | HTMLDivElement>(null)
	const [endCatchRef, setEndCatchRef] = useState<null | HTMLDivElement>(null)
	const [isStartVisible, setIsStartVisible] = useState(true)
	const [isEndVisible, setIsEndVisible] = useState(true)
	const carouselRef = useRef<HTMLDivElement>(null)

	useIntersectionObserver(
		startCatchRef,
		useCallback((entry) => setIsStartVisible(entry.isIntersecting), [])
	)
	useIntersectionObserver(
		endCatchRef,
		useCallback((entry) => setIsEndVisible(entry.isIntersecting), [])
	)

	const scrollToPrevious = useCallback(() => {
		if (carouselRef.current) {
			scrollBy(carouselRef.current, 0, -CAROUSEL_SCROLL_BY_DISTANCE)
		}
	}, [])

	const scrollToNext = useCallback(() => {
		if (carouselRef.current) {
			scrollBy(carouselRef.current, 0, CAROUSEL_SCROLL_BY_DISTANCE)
		}
	}, [])

	const href = items[0] ? getFomoFeedVideoUrl(items[0]) : '/feed'

	return (
		<div
			className={clsx(
				style.Container,
				props.background?.type && style[props.background.type],
				isStartVisible && style.is_startVisible,
				isEndVisible && style.is_endVisible
			)}
			data-track-scroll-to={JSON.stringify({ event: 'scrollTo_feed' })}>
			{props.background && <Background color={props.background} />}
			{props.title && (
				<Container>
					<div className={style.Head}>
						<h2 className={style.Title}>
							{/* @TODO: use link href from contember */}
							<Link
								href={href}
								className={style.TitleLink}
								dataAttributes={{
									'track-click': JSON.stringify({
										event: 'clickTo_content',
										clickTo: 'fomo-feed',
										link: {
											type: 'video',
											text: 'Fomo feed',
											url: process.env.NEXT_PUBLIC_BASE_URL + href.substring(1),
										},
									}),
								}}>
								{props.title}
							</Link>
						</h2>
						<div className={style.Partners}>
							{!!props.partners?.length && (
								<PartnerGrid title="od partnerů" partners={props.partners} />
							)}
						</div>
					</div>
				</Container>
			)}
			<div className={style.Items}>
				<div className={style.ItemsCarousel} ref={carouselRef}>
					<div className={style.Catch} ref={setStartCatchRef} />
					<div className={style.ItemsIn}>
						{items.map((video, index) => (
							<FomoFeedItem key={video.id} video={video} position={index + 1} />
						))}
						<div>
							<IntersectionNavigator
								onNext={() => {
									if (currentFeed.hasNextPage) {
										handleNext()
									}
								}}
							/>
						</div>
					</div>
					<div className={style.Catch} ref={setEndCatchRef} />
				</div>
				<div className={style.Controls}>
					<div className={style.ControlsPrevious}>
						<Arrow onClick={scrollToPrevious} direction="previous" />
					</div>
					<div className={style.ControlsNext}>
						<Arrow onClick={scrollToNext} direction="next" />
					</div>
				</div>
			</div>
		</div>
	)
}

export function FomoFeedItem({ video, position }: { video: FeedVideoResult; position: number }) {
	const [itemInRef, isVisible] = useVisible<HTMLAnchorElement>()
	const isHovered = useHover(itemInRef, {})

	return (
		<div
			className={clsx(style.Item, isHovered && style.is_hovered)}
			style={
				{
					['--FomoFeed-item-width']: `${video.video?.width || 1}`,
					['--FomoFeed-item-height']: `${video.video?.height || 1}`,
				} as CSSProperties
			}
			data-track-list={JSON.stringify({
				item: {
					type: video.promo ? 'promo_feed_item' : 'feed_item',
					id: video.id,
					list: 'FomoFeed',
					position: position,
					title: video.text,
					author: [video.author?.name],
					systemId: 'fomo',
					url: getBaseUrl() + getFomoFeedVideoUrl(video),
				},
			})}>
			<Link
				href={getFomoFeedVideoUrl(video)}
				className={style.ItemIn}
				ref={itemInRef}
				dataAttributes={{
					'track-click': JSON.stringify({
						event: 'clickTo_content',
						clickTo: 'fomo-feed-item',
						link: {
							type: 'video',
							text: video.text,
							url: process.env.NEXT_PUBLIC_BASE_URL + getFomoFeedVideoUrl(video).substring(1),
						},
					}),
				}}>
				<div className={style.Author}>
					<FeedAuthor author={video.author} partner={video.promoPartner?.partner} />
				</div>
				{video.video?.videoOid && (
					<div className={style.Preview}>
						<Image
							src={`https://videodelivery.net/${video.video.videoOid}/thumbnails/thumbnail.jpg?height=960`}
							alt={video.text ?? ''}
							layout="fill"
							objectFit="cover"
							sizes="270px"
						/>
						{isVisible > 0 && (
							<FomoFeedItemVideoPreview videoOid={video.video.videoOid} isPlaying={isHovered} />
						)}
					</div>
				)}
				<h3 className={style.Title}>
					<span className={style.Play}>
						<PlayIcon />
					</span>
					{video.text}
				</h3>
			</Link>
		</div>
	)
}

export function FomoFeedItemVideoPreview({
	videoOid,
	isPlaying,
}: {
	videoOid: string
	isPlaying: boolean
}) {
	const streamRef = useRef<StreamPlayerApi>()
	const [isPlayable, setIsPlayable] = useState(false)

	useEffect(() => {
		if (streamRef.current) {
			if (isPlaying) {
				streamRef.current.play()
			} else {
				streamRef.current.pause()
			}
		}
	}, [isPlaying])

	return (
		<div className={clsx(style.Video, isPlayable && style.is_playable)}>
			<FeedVideo
				src={videoOid}
				preload
				streamRef={streamRef}
				loop
				muted
				onCanPlay={() => {
					setIsPlayable(true)
				}}
				onTimeUpdate={() => {
					if (streamRef.current) {
						// Play only first 4 seconds
						if (streamRef.current.currentTime > 4) {
							streamRef.current.currentTime = 0
						}
					}
				}}
			/>
		</div>
	)
}
