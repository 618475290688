import clsx from 'clsx'
import type { FunctionComponent } from 'react'
import { ColorEnum } from '../../generated/content'
import type { ColorResult } from '../data/fragments/ColorFragment'
import style from './Background.module.sass'
import { Gradient } from './Gradient'

export type BackgroundProps = {
	color: ColorResult
	fixed?: boolean
}

export const Background: FunctionComponent<BackgroundProps> = ({
	color: { type, hex, gradient },
	fixed = false,
}) => {
	return (
		<div
			className={clsx(style.wrapper, fixed && style.view_fixed)}
			style={
				{
					['--Background-color']: hex,
				} as React.CSSProperties
			}>
			{type === ColorEnum.gradient && <Gradient {...gradient} />}
		</div>
	)
}
