import type { FunctionComponent } from 'react'
import type { AuthorResult } from '../data/fragments/AuthorFragment'
import type { PartnerResult } from '../data/fragments/PartnerFragment'
import style from './FeedAuthor.module.sass'
import { ImgixImage } from './ImgixImage'

export type FeedAuthorProps = {
	author?: AuthorResult
	partner?: PartnerResult
}

export const FeedAuthor: FunctionComponent<FeedAuthorProps> = ({ author, partner }) => {
	const name = partner?.name ?? author?.nick ?? author?.name ?? 'Neznámý autor'
	const avatar = partner?.icon ?? author?.avatar

	return (
		<div className={style.wrapper}>
			{avatar && (
				<div className={style.avatar}>
					<div className={style.avatarIn}>
						<ImgixImage src={avatar.url} layout="fill" objectFit="cover" alt="" />
					</div>
				</div>
			)}
			<div className={style.name}>{name}</div>
		</div>
	)
}
