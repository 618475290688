import { useEffect } from 'react'

interface Args extends IntersectionObserverInit {
	freezeOnceVisible?: boolean
}

export function useIntersectionObserver(
	elementRef: Element | null,
	onChange: (entry: IntersectionObserverEntry) => void,
	{ threshold = 0, root = null, rootMargin = '0%' }: Args = {}
) {
	useEffect(() => {
		const hasIOSupport = !!window.IntersectionObserver

		if (!hasIOSupport || !elementRef) return

		const observerParams = { threshold, root, rootMargin }
		const observer = new IntersectionObserver(([entry]) => onChange(entry), observerParams)

		observer.observe(elementRef)

		return () => observer.disconnect()
	}, [elementRef, onChange, root, rootMargin, threshold])
}
