export function nthInsert<Item>(items1: Item[], items2: Item[], nth = 1, insertRest = false) {
	const result: Item[] = []

	const stack = [...items2]

	for (const i in items1) {
		result.push(items1[i])
		if ((1 + Number(i)) % nth === 0) {
			const insert = stack.shift()
			if (insert) {
				result.push(insert)
			}
		}
	}

	if (insertRest) {
		return [...result, ...stack]
	}

	return result
}
