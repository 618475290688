import useIntersectionObserver from '@react-hook/intersection-observer'
import React from 'react'
import { Button } from './Button'
import { Container } from './Container'
import style from './IntersectionNavigator.module.sass'

export function IntersectionNavigator(props: { children?: React.ReactNode; onNext: () => void }) {
	const { onNext } = props
	const [ref, setRef] = React.useState<null | HTMLDivElement>(null)
	const { isIntersecting } = useIntersectionObserver(ref)

	React.useEffect(() => {
		if (isIntersecting) {
			onNext()
		}
	}, [onNext, isIntersecting])

	return (
		<div ref={setRef} className={style.wrapper}>
			{props.children ? (
				<Container size="normal">
					<Button type="button" onClick={onNext}>
						{props.children}
					</Button>
				</Container>
			) : null}
		</div>
	)
}
