import type { PartnerResult } from '../data/fragments/PartnerFragment'
import { Link } from './Link'
import { ContemberImage } from './ContemberImage'
import style from './PartnerAvatar.module.sass'

export function PartnerAvatar(props: PartnerResult) {
	const inner = (
		<div className={style.image}>
			{props.icon && <ContemberImage image={props.icon} layout="fill" objectFit="cover" />}
		</div>
	)

	if (props.link) {
		return (
			<Link link={props.link} className={style.wrapper}>
				{inner}
			</Link>
		)
	}
	return <div className={style.wrapper}>{inner}</div>
}
