import React from 'react'

export function usePagination<Value extends { paged: number }>(
	initial: Value[],
	loader: (paged: number) => Promise<Value>
) {
	const [memoLoader] = React.useState(() => loader)
	const [values, setValues] = React.useState(initial)

	const loaders = React.useRef<Record<number, Promise<Value> | undefined>>({})

	const mounted = React.useRef(true)

	React.useEffect(() => {
		return () => {
			mounted.current = false
		}
	}, [])

	const nextPage = 1 + Math.max(...values.map((value) => value.paged))

	const handleNext = React.useCallback(
		(paged?: number) => {
			const loadingPage = paged ?? nextPage

			if (!loaders.current[loadingPage]) {
				const req = memoLoader(loadingPage)
				loaders.current[loadingPage] = req
				req.then((data) => {
					if (mounted.current) {
						setValues((values) => {
							return [...values, data].sort((a, b) => a.paged - b.paged)
						})
					}
				})
			}
		},
		[nextPage, memoLoader]
	)

	return [values, handleNext] as const
}
