import clsx from 'clsx'
import type { ReactNode } from 'react'
import type { PartnerWithLatestVideoResult } from '../data/fragments/PartnerFragment'
import { getFomoFeedVideoUrl } from '../data/helpers/getFomoFeedVideoUrl'
import { Link } from './Link'
import { PartnerAvatar } from './PartnerAvatar'
import style from './PartnerGrid.module.sass'

export type PartnerGridProps = {
	title: ReactNode
	view?: 'dark' | 'light'
	partners: Array<PartnerWithLatestVideoResult>
}

export function PartnerGrid(props: PartnerGridProps) {
	const dark = props.view === 'dark'
	return (
		<div className={clsx(style.wrapper, dark && style.dark)}>
			{props.title && <h4 className={style.title}>{props.title}</h4>}
			{props.partners.map(
				(partner) =>
					partner.feedVideos[0]?.video && (
						<Link
							href={getFomoFeedVideoUrl(partner.feedVideos[0]?.video)}
							className={style.partner}
							key={partner.id}
							dataAttributes={{
								'track-click': JSON.stringify({
									event: 'clickTo_content',
									clickTo: 'partner-grid',
									link: {
										type: 'video',
										text: partner.name,
										url:
											process.env.NEXT_PUBLIC_BASE_URL +
											getFomoFeedVideoUrl(partner.feedVideos[0]?.video).substring(1),
									},
								}),
							}}>
							<PartnerAvatar {...partner} />
						</Link>
					)
			)}
		</div>
	)
}
