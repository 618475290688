import clsx from 'clsx'
import type { FunctionComponent } from 'react'
import style from './Arrow.module.sass'

export type ArrowProps = {
	direction: 'previous' | 'next'
	onClick: () => void
}

export const Arrow: FunctionComponent<ArrowProps> = ({ direction, onClick }) => {
	return (
		<button
			type="button"
			className={clsx(
				style.wrapper,
				direction === 'previous' && style.view_direction_previous,
				direction === 'next' && style.view_direction_next
			)}
			onClick={onClick}
			aria-label={direction === 'previous' ? 'předchozí' : 'další'}
		/>
	)
}
