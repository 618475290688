import { FunctionComponent, useMemo } from 'react'
import style from './Gradient.module.sass'

export type GradientProps = {
	hex1?: string
	hex2?: string
	angle?: number // from 0 to 360
	hint?: number // from 0 to 100
}

const parseHexColor = (hex: string) => [
	parseInt(hex.substr(1, 2), 16),
	parseInt(hex.substr(3, 2), 16),
	parseInt(hex.substr(5, 2), 16),
]

const colorToHex = (color: ReturnType<typeof parseHexColor>) =>
	`#${color.map((c) => c.toString(16).padStart(2, '0')).join('')}`

export const Gradient: FunctionComponent<GradientProps> = (props) => {
	const hex1 = props.hex1 ?? '#ffffff'
	const hex2 = props.hex2 ?? '#ffffff'
	const angle = props.angle ?? 180
	const hint = props.hint ?? 50

	const hexMiddle = useMemo(() => {
		const color1 = parseHexColor(hex1)
		const color2 = parseHexColor(hex2)

		// @TODO: calculate more visually pleasing middle color
		const colorMiddle = color1.map((c1, i) => Math.round(c1 + (color2[i] - c1) / 2))
		return colorToHex(colorMiddle)
	}, [hex1, hex2])
	return (
		<div
			className={style.wrapper}
			style={
				{
					['--Gradient-color-start']: hex1,
					['--Gradient-color-middle']: hexMiddle,
					['--Gradient-color-end']: hex2,
					['--Gradient-angle']: `${angle}deg`,
					['--Gradient-hint']: `${hint}%`,
				} as React.CSSProperties
			}
		/>
	)
}
